import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '~/pages/Login'
import Main from '~/pages/Main'
import Station from '~/pages/Station'
// import Contact from '~/pages/Contact'
import Privacy from '~/pages/Privacy'
import Reports from '~/pages/Reports'
import ReportsNew from '~/pages/ReportsNew'
import ReportsWaterCharge from '~/pages/ReportsWaterCharge'
import ReportsMotoHours from '~/pages/ReportsMotoHours'
import Settings from '~/pages/Settings'
import Notifications from '~/pages/Notifications'
import Irrigation from '~/pages/Irrigation'
import Meteos from '~/pages/Meteos'
import Meteo from '~/pages/Meteo'
import ForecastFullscreen from '~/pages/ForecastFullscreen'
import Admin from '~/pages/Admin'
import store from '~/store'

Vue.use(VueRouter)

const routes = [
  {name: 'login', path: '/login', component: Login, meta: {public: true, onlyWhenLoggedOut: true, layout: 'Centered'}},
  {name: 'main', path: '/', component: Main},
  {name: 'station', path: '/s', component: Station},
  {name: 'meteo', path: '/m', component: Meteo},
  {name: 'forecast', path: '/f', component: ForecastFullscreen},
  {name: 'irrigation', path: '/irrigation', component: Irrigation},
  {name: 'meteos', path: '/meteos', component: Meteos},
  {name: 'reportsnew', path: '/reports', component: ReportsNew},
  {name: 'reports', path: '/reports-old', component: Reports},
  {name: 'reports2', path: '/reports2', component: ReportsWaterCharge},
  {name: 'reportMotoHours', path: '/report/mh', component: ReportsMotoHours},
  // {name: 'help', path: '/help', component: Contact},
  // {name: 'contact', path: '/contact', component: Contact},
  {name: 'privacy', path: '/privacy', component: Privacy, meta: {public: true, layout: 'Centered'}},
  {name: 'settings', path: '/settings', component: Settings},
  {name: 'notifications', path: '/notifications', component: Notifications},
  {name: 'admin', path: '/admin', component: Admin, meta: {admin: true}}
]

const router = new VueRouter({
  routes,
  mode: 'hash'
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const forAdminOnly = to.matched.some(record => record.meta.admin)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const isLoggedIn = store.getters['auth/isLoggedIn']
  const isAdmin = store.getters['auth/isAdmin']

  if (!isPublic && !isLoggedIn) {
    return next({
      path: '/login',
      query: {redirect: to.fullPath}
    })
  }
  if (forAdminOnly && !isAdmin) {
    return next({
      path: '/'
    })
  }
  if (onlyWhenLoggedOut && isLoggedIn) {
    return next('/')
  }
  return next()
})

export default router
