export default {
  login: [
    {key: 'email', label: 'form.email', type: 'string'},
    {key: 'password', label: 'form.password', type: 'string', inputOptions: {type: 'password'}}
  ],
  restorePassword: [
    {key: 'email', label: 'form.email', type: 'string'}
  ],
  item: [
    {key: 'deviceSettings.workPlan.speed', type: 'number', label: 'form.speed', inputOptions: {suffix: '%', hideDetails: true}, min: 0, max: 100, cols: 3, decimal: 2},
    {key: 'deviceSettings.workPlan.depth', type: 'number', label: 'form.depth', inputOptions: {suffix: 'form.depthSuffix', hideDetails: true}, default: 0, cols: 3, decimal: 2},
    {key: 'deviceSettings.workPlan.time', type: 'number', label: 'form.time', inputOptions: {hideDetails: true}, default: 0, cols: 3, decimal: 2},
    {key: 'deviceSettings.workPlan.circles', bauer: true, type: 'number', label: 'form.circles', inputOptions: {hideDetails: true}, default: 0, min: 1, max: 12, cols: 3},
    {key: 'deviceSettings.workPlan.timeWithHoseStops', show: () => false, type: 'number', label: 'form.timeWithHoseStops', inputOptions: {suffix: 'form.timeSuffix', hideDetails: true}, default: 0, cols: 3, decimal: 2},
    {key: 'deviceStatus.pressure', label: 'form.pressure', inputOptions: {suffix: 'form.pressureSuffix', hideDetails: true, disabled: true, outlined: true}, cols: 6},
    {key: 'deviceStatus.waterConsumption', show: () => false, label: 'form.flow', inputOptions: {suffix: 'form.flowSuffix', hideDetails: true, disabled: true, outlined: true}, cols: 6},
    {key: 'deviceStatus.voltage', label: 'form.voltage', inputOptions: {suffix: 'form.voltageSuffix', hideDetails: true, disabled: true, outlined: true}, cols: 6},
    {key: 'deviceStatus.temperature', show: () => false, label: 'form.temperature', diesel: false, inputOptions: {suffix: 'form.temperatureSuffix', hideDetails: true, disabled: true, outlined: true}, default: 0, cols: 6},
    {key: 'deviceStatus.motohours.value', show: () => false, label: 'form.motohour', diesel: true, inputOptions: {suffix: 'form.timeSuffix', hideDetails: true, disabled: true, outlined: true}, default: 0, cols: 6},
    {key: 'deviceSettings.workPlan.serviceStop', type: 'number', forItemType: 'circle', label: 'form.serviceStop', inputOptions: {suffix: '°', hideDetails: true, placeholder: '0 - 360°'}, min: 0, max: 360, cols: 3, decimal: 1},
    {key: 'deviceSettings.workPlan.serviceStop', type: 'number', forItemType: 'lateral', label: 'form.serviceStop', inputOptions: {suffix: 'form.radiusSuffix', hideDetails: true}, min: 0, cols: 6, decimal: 0},
    {key: 'deviceSettings.workPlan.startAngle', show: () => false, type: 'number', label: 'form.startAngle', inputOptions: {suffix: '°', hideDetails: true, placeholder: '0 - 360°'}, min: 0, max: 360, cols: 3, decimal: 1},
    {key: 'deviceSettings.workPlan.endAngle', show: () => false, type: 'number', label: 'form.endAngle', inputOptions: {suffix: '°', hideDetails: true, placeholder: '0 - 360°'}, min: 0, max: 360, cols: 3, decimal: 1},
    {key: 'deviceSettings.workPlan.startPosition', show: () => false, type: 'number'},
    {key: 'deviceSettings.workPlan.serviceStopRepeat', bauer: false, type: 'boolean', label: 'form.serviceStopRepeat', cols: 6},
    {key: 'deviceSettings.workPlan.serviceStopSwitch', bauer: true, type: 'boolean', label: 'form.toggle', cols: 3},
    {key: 'deviceSettings.workPlan.parking',  bauer: true, type: 'number', forItemType: 'circle', label: 'form.parking', inputOptions: {suffix: '°', hideDetails: true, placeholder: '0 - 360°'}, min: 0, max: 360, cols: 3, decimal: 1},
    {key: 'deviceSettings.workPlan.parkingSwitch', bauer: true, type: 'boolean', label: 'form.toggle', cols: 3},
    {key: 'deviceSettings.workPlan.comment', type: 'text', label: 'form.comment', inputOptions: {textarea: true, outlined: true, rows: 3, hideDetails: true}, show: () => false},
    {key: 'deviceSettings.workPlan.mode', type: 'select', dataSource: ['Ручное управление', 'Авто'], show: () => false}
  ],
  itemMapLateral: [
    {key: 'startLat', type: 'number', label: 'form.startLat', inputOptions: {suffix: '°', hideDetails: true}, decimal: 6},
    {key: 'startLng', type: 'number', label: 'form.startLng', inputOptions: {suffix: '°', hideDetails: true}, decimal: 6},
    {key: 'endLat', type: 'number', label: 'form.endLat', inputOptions: {suffix: '°', hideDetails: true}, decimal: 6},
    {key: 'endLng', type: 'number', label: 'form.endLng', inputOptions: {suffix: '°', hideDetails: true}, decimal: 6},
    {key: 'height', type: 'number', label: 'form.lateralHeight', inputOptions: {suffix: 'form.radiusSuffix', hideDetails: true}, decimal: 0},
    {key: 'length', type: 'number', label: 'form.lateralLength', inputOptions: {suffix: 'form.radiusSuffix', hideDetails: true}, decimal: 0},
    {key: 'startPosition', type: 'number', label: 'form.startPosition', show: () => false, inputOptions: {suffix: 'form.radiusSuffix', hideDetails: true}, decimal: 0},
    {key: 'endPosition', type: 'number', label: 'form.endPosition', inputOptions: {suffix: 'form.radiusSuffix', hideDetails: true}, decimal: 0}
  ],
  itemMapCircle: [
    {key: 'lat', type: 'number', label: 'form.lat', inputOptions: {suffix: '°', hideDetails: true}, decimal: 6},
    {key: 'lng', type: 'number', label: 'form.lng', inputOptions: {suffix: '°', hideDetails: true}, decimal: 6},
    {key: 'radius', type: 'number', label: 'form.radius', inputOptions: {suffix: 'form.radiusSuffix', hideDetails: true}, decimal: 0},
    {key: 'startAngle', type: 'number', label: 'form.startAngle', inputOptions: {suffix: '°', hideDetails: true}, decimal: 1, allowNegative: true},
    {key: 'endAngle', type: 'number', label: 'form.endAngle', inputOptions: {suffix: '°', hideDetails: true}, decimal: 1},
    {key: 'offsetAngle', type: 'number', label: 'form.offsetAngle', inputOptions: {suffix: '°', hideDetails: true},  default: 0, decimal: 0, allowNegative: true},
    {key: 'placement', label: 'form.placement', type: 'select', dataSource:[], show: (instance) => instance.placement, multiple: false}
  ],
  gun: [
    {key: 'status', type: 'boolean', switchOptions: {hideDetails: true, trueValue: 'on', falseValue: 'off', class: 'ma-0 pa-0'}},
    {key: 'startAngle', type: 'number', inputOptions: {suffix: '°', class: 'ma-0 pa-0', hideDetails: true}, min: 0, decimal: 1},
    {key: 'endAngle', type: 'number', inputOptions: {suffix: '°', class: 'ma-0 pa-0', hideDetails: true}, min: 0, decimal: 1},
    {key: 'start', type: 'number', inputOptions: {suffix: 'form.radiusSuffix', class: 'ma-0 pa-0', hideDetails: true}, min: 0, decimal: 0},
    {key: 'end', type: 'number', inputOptions: {suffix: 'form.radiusSuffix', class: 'ma-0 pa-0', hideDetails: true}, min: 0, decimal: 0},
    {key: 'delay', type: 'number', inputOptions: {suffix: 'form.timeSuffixSec', class: 'ma-0 pa-0', hideDetails: true}, min: 0, decimal: 1},
  ],
  hoseStop: [
    {key: 'position', type: 'number', inputOptions: {suffix: 'form.hoseStopSuffix', class: 'ma-0 pa-0', hideDetails: true}, min: 0, max: 10000, decimal: 1},
    {key: 'alertDistance', show: () => false, type: 'number', inputOptions: {suffix: 'form.hoseStopSuffix', class: 'ma-0 pa-0', hideDetails: true}, min: 0, max: 10000, decimal: 1},
    {key: 'align', type: 'select', dataSource: ['Слева', 'Справа', 'В центре']},
    {key: 'visibility', type: 'boolean', switchOptions: {hideDetails: true, trueValue: 'on', falseValue: 'off', class: 'ma-0 pa-0'}}
  ],
  areaPlan: [
    {key: 'start', type: 'number', inputOptions: {suffix: '°', class: 'ma-0 pa-0', hideDetails: true}, min: 0, max: 360, cols: 2},
    {key: 'end', type: 'number', inputOptions: {suffix: '°', class: 'ma-0 pa-0', hideDetails: true}, min: 0, max: 360, cols: 2},
    {key: 'speed', type: 'number', inputOptions: {suffix: '%', class: 'ma-0 pa-0', hideDetails: true}, min: 0, max: 100, cols: 4}
  ],
  watermark: [
    {key: 'date', type: 'date', cols: 2},
    {key: 'depth', type: 'select', dataSource: [10, 30, 50]},
    {key: 'value', type: 'number', inputOptions: {suffix: 'form.watermarkSuffix', class: 'ma-0 pa-0', hideDetails: true}, min: 0, max: 10000, decimal: 1}
  ],
  soil: [
    {key: 'wilting_point', type: 'number', label: 'watermark.wilting_point', inputOptions: {suffix: 'form.watermarkSuffix', class: 'ma-0 pa-0', hideDetails: true}, min: 0, max: 100},
    {key: 'start_watering', type: 'number', label: 'watermark.start_watering', inputOptions: {suffix: 'form.watermarkSuffix', class: 'ma-0 pa-0', hideDetails: true}, min: 0, max: 100},
    {key: 'stop_watering', type: 'number', label: 'watermark.stop_watering', inputOptions: {suffix: 'form.watermarkSuffix', class: 'ma-0 pa-0', hideDetails: true}, min: 0, max: 100},
    {key: 'smallest_field_capacity', type: 'number', label: 'watermark.smallest_field_capacity', inputOptions: {suffix: 'form.watermarkSuffix', class: 'ma-0 pa-0', hideDetails: true}, min: 0, max: 100}
  ],
  camera: [
    {key: 'ip', label: 'form.camera.ip', type: 'string', cols: 5},
    {key: 'port', label: 'form.camera.port', type: 'string', cols: 5},
    {key: 'status', label: 'form.camera.status', type: 'select', dataSource: ['on', 'off'], cols: 2},
    {key: 'login', label: 'form.camera.login', type: 'string', cols: 6},
    {key: 'password', label: 'form.camera.password', type: 'string', cols: 6},
    {key: 'camera_num', label: 'form.camera.cameraNum', type: 'string', cols: 6}
  ],
  adminItem: [
    {key: 'uid', permission: 'Add access to Organization', label: 'form.device.uid', type: 'string', cols: 12},
    {key: 'mac', permission: 'Add access to Organization', label: 'form.device.mac', type: 'string', cols: 12},
    {key: 'organization', permission: 'Add access to Organization', label: 'form.user.organization', type: 'select', dataSource: {path: 'admin.organizations'}, selectOptions: {chips: true, smallChips: true, deletableChips: true}, multiple: false}
  ],
  notifications: [
    {key: 'id', show: () => false},
    {key: 'all_devices', label: 'form.user.allDevices', type: 'boolean'},
    {key: 'devices', saveCommaMode: true, saveAs: ['device', 'devices'], show: (instance) => !instance.all_devices, label: 'form.user.devices', type: 'select', dataSource: {path: 'rtu.list'}, selectOptions: {chips: true, smallChips: true, deletableChips: true}, multiple: true}
  ],
  user: [
    {key: 'id', show: () => false},
    {key: 'name', label: 'form.user.name', type: 'string'},
    {key: 'email', label: 'form.user.email', type: 'string'},
    {key: 'password', label: 'form.user.password', type: 'string', show: (instance) => !instance.id },
    {key: 'role', label: 'form.user.role', type: 'select', dataSource: ['SuperAdmin', 'Admin', 'User', 'ReadOnly']},
    {key: 'organizations', saveCommaMode: true, saveAs: ['organization', 'organizations'], permission: 'Add access to Organization', label: 'form.user.organization', type: 'select', dataSource: {path: 'admin.organizations'}, selectOptions: {chips: true, smallChips: true, deletableChips: true}, multiple: true},
    {key: 'all_devices', label: 'form.user.allDevices', type: 'boolean'},
    {key: 'devices', saveCommaMode: true, saveAs: ['device', 'devices'], show: (instance) => !instance.all_devices, label: 'form.user.devices', type: 'select', dataSource: {path: 'rtu.list'}, selectOptions: {chips: true, smallChips: true, deletableChips: true}, multiple: true}
  ],
  organization: [
    {key: 'id', show: () => false},
    {key: 'name', label: 'form.organization.name',type: 'string'},
    {key: 'devices', saveCommaMode: true, saveAs: ['device', 'devices'], label: 'form.organization.devices', type: 'select', dataSource: {path: 'rtu.list'}, selectOptions: {chips: true, smallChips: true, deletableChips: true}, multiple: true},
    {key: 'ip', label: 'form.organization.proxy.ip', type: 'string', cols: 5},
    {key: 'port', label: 'form.organization.proxy.port', type: 'string', cols: 5},
  ],
  pivot: [
    {key: 'id', show: () => false},
    {key: 'name', label: 'form.pivot.name', type: 'string'},
    {key: 'uuid', label: 'form.pivot.uuid', type: 'string', cols: 4},
    {key: 'ip', label: 'form.pivot.ip', type: 'string', cols: 4},
    {key: 'radius', type: 'number', label: 'form.radius', inputOptions: {suffix: 'form.radiusSuffix', hideDetails: true}, decimal: 0, cols: 4},
    {key: 'lat', type: 'number', label: 'form.lat', inputOptions: {suffix: '°', hideDetails: true}, decimal: 6,  cols: 6},
    {key: 'lng', type: 'number', label: 'form.lng', inputOptions: {suffix: '°', hideDetails: true}, decimal: 6,  cols: 6},
    {key: 'manufacturers', label: 'form.pivot.manufacturer', type: 'select', dataSource: {path: 'admin.manufacturers'}, cols: 6},
    {key: 'organizations', saveCommaMode: true, saveAs: ['organization', 'organizations'], permission: 'Add access to Organization', label: 'form.user.organization', type: 'select', dataSource: {path: 'admin.organizations'}, selectOptions: {chips: true, smallChips: true, deletableChips: true}, multiple: true, cols: 6},
  ],
  reportFilter: [
    {key: 'date_from', label: 'filter.date_from', type: 'date', cols: 2},
    {key: 'date_to', label: 'filter.date_to', type: 'date', cols: 2},
    {key: 'time_from', label: 'filter.time_from', type: 'time', cols: 1},
    {key: 'time_to', label: 'filter.time_to', type: 'time', cols: 1},
    {key: 'devices', saveCommaMode: true, saveAs: ['device', 'devices'], label: 'form.organization.devices', type: 'select', dataSource: {path: 'rtu.list'}, selectOptions: {chips: true, smallChips: true, deletableChips: true}, multiple: false, cols: 3},
    // {key: 'organizations', saveCommaMode: true, saveAs: ['organization', 'organizations'], permission: 'Add access to Organization', label: 'form.user.organization', type: 'select', dataSource: {path: 'admin.organizations'}, selectOptions: {chips: true, smallChips: true, deletableChips: true}, multiple: true, cols: 2, show: (instance) => true},
    {key: 'status', saveCommaMode: true, saveAs: ['status'], label: 'report.status', type: 'select', dataSource: ['Движение вперед', 
    'Движение вперед с водой', 
    'Движение вперед с удобрением', 
    'Движение назад', 
    'Движение назад с водой', 
    'Движение назад с удобрением', 
    'Движение прекращено', 
    'Ошибка низкого давления', 
    'Ошибка выравнивания', 
    'Остановка для обслуживания', 
    'Нет связи'], selectOptions: {chips: true, smallChips: true, deletableChips: true}, multiple: true, cols: 3, show: (instance) => true},
  ],
  stationFilter: [
    {key: 'from', label: 'filter.date_from', type: 'date', cols: 4},
    {key: 'to', label: 'filter.date_to', type: 'date', cols: 4},
    {key: 'period', saveCommaMode: true, saveAs: ['period'], label: 'filter.period', type: 'select', dataSource: ['Час', 'День'], cols: 4},
  ],
  watermarkFilter: [
    {key: 'from', label: 'filter.date_from', type: 'date', cols: 6},
    {key: 'to', label: 'filter.date_to', type: 'date', cols: 6},
  ],
  softBarrier: [
    {key: 'status', show: () => false, type: 'boolean', switchOptions: {hideDetails: true, trueValue: 'on', falseValue: 'off', class: 'ma-0 pa-0'}},
    {key: 'startAngle', label: 'form.barrierFrom', type: 'number', inputOptions: {suffix: '°', hideDetails: true}, min: 0, max: 360, default: 0, cols: 6, decimal: 1},
    {key: 'endAngle', label: 'form.barrierTo', type: 'number', inputOptions: {suffix: '°', hideDetails: true}, min: 0, max: 360, default: 0, cols: 6, decimal: 1},

    // {key: 'Hold For', label: 'form.barrierHoldFor', type: 'number', inputOptions: {suffix: 'form.timeSuffixMinute', hideDetails: true}, min: 0, max: 360, default: 0, cols: 4, decimal: 1},
  ],
  primarySettings: [
    {key: 'Machine Id', label: '', type: 'string', inputOptions: {prefix: 'FC', hideDetails: true}},
    {key: 'Designed Flow', label: '', type: 'number', inputOptions: {suffix: 'лс', hideDetails: true}},
    {key: 'Circle Time', label: '', type: 'number', inputOptions: {suffix: 'form.timeSuffixMinute', hideDetails: true}},
    {key: 'Radius', label: '', type: 'number', inputOptions: {suffix: 'form.radiusSuffix', hideDetails: true}},
    {key: 'Reverse Delay', label: '', type: 'number', inputOptions: {suffix: 'form.timeSuffixSec', hideDetails: true}},
    {key: 'Low Pressure', label: '', type: 'number', inputOptions: {suffix: 'form.pressureSuffix', hideDetails: true}},
    {key: 'High Pressure', label: '', type: 'number', inputOptions: {suffix: 'form.pressureSuffix', hideDetails: true}},
    {key: 'Critical Low Pressure', label: '', type: 'number', inputOptions: {suffix: 'form.pressureSuffix', hideDetails: true}},
    {key: 'Critical High Pressure', label: '', type: 'number', inputOptions: {suffix: 'form.pressureSuffix', hideDetails: true}},
    {key: 'Pressure P0 Val', label: '', type: 'number', inputOptions: {suffix: '', hideDetails: true}},
    {key: 'Pressure P1 Val', label: '', type: 'number', inputOptions: {suffix: '', hideDetails: true}},
    {key: 'Pressure P1 Bar', label: '', type: 'number', inputOptions: {suffix: 'form.pressureSuffix', hideDetails: true}},
    {key: 'Pressure Sens Max', label: '', type: 'number', inputOptions: {suffix: 'form.pressureSuffix', hideDetails: true}},
    {key: 'Water Cons P0 Val', label: '', type: 'number', inputOptions: {suffix: '', hideDetails: true}},
    {key: 'Water Cons P1 Val', label: '', type: 'number', inputOptions: {suffix: '', hideDetails: true}},
    {key: 'Water Cons P1', label: '', type: 'number', inputOptions: {suffix: 'form.flowSuffix', hideDetails: true}},
    {key: 'Center Lat', label: '', type: 'number', inputOptions: {suffix: '°', hideDetails: true}},
    {key: 'Center Long', label: '', type: 'number', inputOptions: {suffix: '°', hideDetails: true}},
    {key: 'Prepare Delay', label: '', type: 'number', inputOptions: {suffix: 'form.timeSuffixSec', hideDetails: true}},
    {key: 'Use Uart Connect', label: 'form.uart', type: 'boolean', inputOptions: {suffix: 'form.uart', hideDetails: true}},
    {key: 'Use Gnss', label: 'form.gnss', type: 'boolean'},
    {key: 'Start Without Gnss', label: 'form.startWoGnss', type: 'boolean'},
    {key: 'Use Pressure Sensor', label: 'form.sensor', type: 'boolean'},
    {key: 'Pressure Delay', label: '', type: 'number', inputOptions: {suffix: 'form.timeSuffixSec', hideDetails: true}},
    {key: 'Gnss Delay', label: '', type: 'number', inputOptions: {suffix: 'form.timeSuffixSec', hideDetails: true}},
    {key: 'Gnss Delta', label: '', type: 'number', inputOptions: {suffix: 'form.timeSuffixSec', hideDetails: true}},
    {key: 'Low Voltage', label: '', type: 'number', inputOptions: {suffix: 'form.voltageSuffix', hideDetails: true}},
    {key: 'High Voltage', label: '', type: 'number', inputOptions: {suffix: 'form.voltageSuffix', hideDetails: true}},
    {key: 'Server Address', label: '', type: 'string', inputOptions: {hideDetails: true}},
    {key: 'Server Port', label: '', type: 'number', inputOptions: {hideDetails: true}, decimal: 0},
    {key: 'Gnss UID', label: '', type: 'number', inputOptions: {hideDetails: true}, decimal: 0},
  ]
}
